import React, { useEffect, useRef, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import "./AudioPlay.css";

const AutoPlayAudio = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (!initialized && audioRef.current) {
        audioRef.current.play();
        setIsPlaying(true);
        setInitialized(true);
      }
    };

    window.addEventListener("click", handleUserInteraction, { capture: true });
    window.addEventListener("keydown", handleUserInteraction, {
      capture: true,
    });

    return () => {
      window.removeEventListener("click", handleUserInteraction, {
        capture: true,
      });
      window.removeEventListener("keydown", handleUserInteraction, {
        capture: true,
      });
    };
  }, [initialized]);

  useEffect(() => {
    const currentAudioRef = audioRef.current;
    const handleEnded = () => {
      if (currentAudioRef) {
        currentAudioRef.currentTime = 0;
        currentAudioRef.play();
        setIsPlaying(true);
      }
    };

    if (currentAudioRef) {
      currentAudioRef.addEventListener("ended", handleEnded);
    }

    return () => {
      if (currentAudioRef) {
        currentAudioRef.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  const togglePlay = () => {
    console.log("toggled");
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="audio-container">
      <audio ref={audioRef}>
        <source src="/audio/bgm.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <button onClick={togglePlay} className="toggle-btn">
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </button>
    </div>
  );
};

export default AutoPlayAudio;

import React, { useEffect, useRef, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "./Home.css";

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const secondHeadingRef = useRef<HTMLHeadingElement>(null);
  const [ca, setCA] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ca)
      .then(() => {
        console.log("Copied to clipboard:", ca);
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  useEffect(() => {
    setCA("0x3784bb7A2e3Ba761feB8F8C12c1053b30024539a");
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (secondHeadingRef.current) {
      observer2.observe(secondHeadingRef.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);

  return (
    <div className="Home" id={id}>
      <div ref={homeRef} className="Home-container">
        <h1
          id="second-heading"
          style={{ fontSize: "2.25rem", fontWeight: 500, margin: 0 }}
        >
          Introducing Baby biden Coin -
        </h1>
        <h1
          id="second-heading"
          className="second-heading"
          style={{ fontSize: "5rem", fontWeight: 500, margin: 0 }}
        >
          A Crypto Tribute to President <br />
          Joe Biden!
        </h1>
        <div className="Home-iconbtns">
          <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x6e8b8a05cddbecf8647c2eee48eb28a53033b530" target="_blank" rel="noreferrer">
            <img src="/images/home-dextools.svg" alt="dextools" />
          </a>
          <a href="https://t.me/baby_biden" target="_blank" rel="noreferrer">
            <img
              src="/images/Contact-telegram.svg"
              width={64}
              height={64}
              alt="etherscan"
            />
          </a>
          <a
            href="https://x.com/baby__biden?s=20"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/images/x-logo.svg"
              alt="twitter"
              width={64}
              height={64}
            />
          </a>
        </div>
        <h1
          className="CA"
          style={{ fontSize: "2.25rem", fontWeight: 500, margin: "0 0 2rem 0" }}
        >
          Contract Address
        </h1>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="mobile-column"
        >
          <h1
            className="CA"
            style={{
              fontSize: "24px",
              fontWeight: 500,
              margin: 0,
              overflowWrap: "anywhere",
            }}
          >
            {ca}
          </h1>
          <div style={{ display: "flex" }}>
            <button className="copy-btn" onClick={handleCopyToClipboard}>
              <ContentCopyIcon style={{ color: "white" }} />
            </button>
            {showPopup && (
              <div className="popup">
                <p style={{ margin: "0", fontSize: "16px" }}>Copied</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="Main-images Home-image " ref={secondHeadingRef}>
        <img src="/images/1.svg" alt="home" className="Main-pepes Home-pepe" />
      </div>
    </div>
  );
}

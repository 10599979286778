import React, { useEffect, useRef } from "react";
import "./Tokenomics.css";

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  const homeRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 이 값을 조정하여 요소가 화면에 나타나는 정도를 조절할 수 있습니다. 0에서 1사이의 값으로 설정됩니다.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showBottom");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (homeRef.current) {
      observer.observe(homeRef.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);
  return (
    <div className="Tokenomics" id={id}>
      <div className="Tokenomics-container">
        <h1 className="Tokenomics-title" ref={rightRef}>
          TOKENOMICS
        </h1>
        <div className="Tokenomics-divider" />
        <div className="flex-container" ref={homeRef}>
          <div className="Tokenomics-subcontainer">
            <img
              src="/images/supply.png"
              className="img-container"
              alt="supply"
            />
            <h1 className="Tokenomics-subtitle">
              <span>Supply</span>
              <br /> {"1,000,000,000"}
            </h1>
          </div>
          <div className="Tokenomics-subcontainer">
            <img
              src="/images/tax.png"
              className="img-container"
              // style={{
              //   background: "linear-gradient(rgb(0, 74, 173), rgb(23 116 255))",
              // }}
              alt="taxes"
            />

            <h1 className="Tokenomics-subtitle">
              <span>Taxes</span> <br />
              2/2
            </h1>
          </div>
          <div className="Tokenomics-subcontainer">
            <img src="/images/safu.png" className="img-container" alt="safu" />
            <h1 className="Tokenomics-subtitle">
              <span>SAFU</span>
              <br /> LP LOCKED
              <br /> CA Renounced
            </h1>
          </div>
        </div>

        <br />
      </div>
    </div>
  );
}

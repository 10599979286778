import React, { useEffect, useRef } from "react";
import "./About.css";

type AboutProps = {
  id: string;
};

export default function About({ id }: AboutProps) {
  const leftRef = useRef<HTMLDivElement>(null);
  const leftRef_1 = useRef<HTMLDivElement>(null);
  const leftRef_2 = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHeadingElement>(null);
  const rightRef_1 = useRef<HTMLHeadingElement>(null);
  const rightRef_2 = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 이 값을 조정하여 요소가 화면에 나타나는 정도를 조절할 수 있습니다. 0에서 1사이의 값으로 설정됩니다.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showLeft");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    const observer2 = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 요소가 화면에 나타날 때 애니메이션을 트리거합니다.
          entry.target.classList.add("showRight");
          observer.unobserve(entry.target); // 한 번 나타난 후에는 관찰을 중단합니다.
        }
      });
    }, options);

    if (leftRef.current) {
      observer.observe(leftRef.current);
    }

    if (leftRef_1.current) {
      observer.observe(leftRef_1.current);
    }

    if (leftRef_2.current) {
      observer.observe(leftRef_2.current);
    }

    if (rightRef.current) {
      observer2.observe(rightRef.current);
    }

    if (rightRef_1.current) {
      observer2.observe(rightRef_1.current);
    }

    if (rightRef_2.current) {
      observer2.observe(rightRef_2.current);
    }

    // 컴포넌트가 언마운트될 때 관찰을 중단합니다.
    return () => {
      observer.disconnect();
      observer2.disconnect();
    };
  }, []);
  return (
    <div className="About" id={id}>
      <div className="About-container">
        <div className="opacity" ref={leftRef}>
          <h1 className="About-title">$BABYBIDEN</h1>
          <div className="About-divider" />
          <h1 className="About-content">
            Are you ready for the cutest cryptocurrency in town? Say hello to
            Baby biden Coin, a digital token that encapsulates the charm and
            charisma of none other than the 46th President of the United States,
            Joe Biden! Just like President Biden, Baby biden Coin is here to
            bring a breath of fresh air to the crypto world with its adorable
            concept.
          </h1>
          <h1 className="About-content">
            As we navigate the exciting and ever-evolving landscape of
            cryptocurrencies, Baby biden Coin is here to add a touch of
            playfulness and optimism to your digital assets portfolio.
          </h1>
        </div>

        <div className="About-image" ref={rightRef}>
          <img
            src="/images/11.png"
            alt="home2"
            className="Main-pepes About-pepe"
          />
        </div>
      </div>

      <div className="About-container">
        <div className="opacity" ref={leftRef_1}>
          <div className="About-divider" />
          <h1 className="About-content">
            What sets Baby biden Coin apart? It's not just about the blockchain;
            it's about building a community that shares the same values of unity
            and progress. Baby biden Coin aims to foster a sense of
            togetherness, much like the President it pays homage to. Our mission
            is to create a space where crypto enthusiasts can come together,
            celebrate the positive spirit of President Biden, and contribute to
            charitable causes that align with his vision for a better future.
          </h1>
          <h1 className="About-content">
            As we navigate the exciting and ever-evolving landscape of
            cryptocurrencies, Baby biden Coin is here to add a touch of
            playfulness and optimism to your digital assets portfolio.
          </h1>
        </div>

        <div className="About-image" ref={rightRef_1}>
          <img
            src="/images/10.png"
            alt="home2"
            className="Main-pepes About-pepe"
          />
        </div>
      </div>

      <div className="About-container">
        <div className="opacity" ref={leftRef_2}>
          <div className="About-divider" />
          <h1 className="About-content">
            So, whether you're a seasoned crypto investor or just dipping your
            toes into the world of digital assets, Baby JoeCoin welcomes you
            with open arms. Join us in embracing the adorable side of crypto
            while supporting causes that matter. Let's build a community that
            reflects the values of resilience, compassion, and progress embodied
            by President Joe Biden.
          </h1>
          <h1 className="About-content">
            Get ready to embark on a crypto journey like no other with Baby
            biden Coin – where cute meets currency, and community meets
            compassion!
          </h1>
        </div>

        <div className="About-image" ref={rightRef_2}>
          <img
            src="/images/12.png"
            alt="home2"
            className="Main-pepes About-pepe"
          />
        </div>
      </div>
    </div>
  );
}

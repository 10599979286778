import React from "react";
import Header from "../../components/Header/Header";
import Home from "../../components/Home/Home";
import About from "../../components/About/About";
import Tokenomics from "../../components/Tokenomics/Tokenomics";
import Contact from "../../components/Contact/Contact";
import Audio from "../../components/Audio/AudioPlay";
import "./Main.css";

function Main() {
  return (
    <div className="Main">
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap"
        rel="stylesheet"
      />
      <Audio />
      <Header />

      <Home id="home" />
      <About id="about" />
      <Tokenomics id="tokenomics" />
      <Contact />
    </div>
  );
}

export default Main;

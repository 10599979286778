import React, { useState, useEffect } from "react";
import "./Header.css";
import { HashLink } from "react-router-hash-link";

// const navItems = [{ name: "HOME", path: "/" }];

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="Header">
      <HashLink smooth to="/" className="Header-navbar-start">
        <img
          src="apple-icon.png"
          width={64}
          alt="logo"
          style={{ margin: "0 1rem" }}
        />
        {windowWidth > 600 && <span>BABYBIDEN</span>}
        {/* <div className="Header-navbar-Link">
          {navItems.map((item, index) => (
            <HashLink
              key={index}
              smooth
              to={item.path}
              className="Header-navbar-item"
            >
              {item.name}
            </HashLink>
          ))}
        </div> */}
      </HashLink>
      <div className="Header-navbar-end">
        <span className="Header-navbar-btn">
          <a
            href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x3784bb7a2e3ba761feb8f8c12c1053b30024539a"
            target="_blank"
            rel="noreferrer"
          >
            BUY HERE
          </a>
        </span>
      </div>
    </header>
  );
}

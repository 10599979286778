import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="Contact ">
      <h1 className="Contact-title">Community</h1>
      <div className="Contact-iconbtns">
        <a
          href="https://x.com/baby__biden?s=20"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/x-logo-red.svg" alt="twitter" />
        </a>
        <a href="https://t.me/baby_biden" target="_blank" rel="noreferrer">
          <img src="/images/Contact-telegram.svg" alt="etherscan" />
        </a>
      </div>
      <h1 className="Contact-content">
        $BABYBIDEN token has no association with JOE BIDEN. <br /> This token is
        simply paying homage to a meme we all love and recognize.
      </h1>
    </div>
  );
}
